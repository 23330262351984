import React from "react";
import "./style.scss";

const TodoItem = ({todo, editTodo, removeTodo, index}) => {
    const handleChange = () => {
        todo.completed = !todo.completed;
        editTodo(todo);
    };

    const handleDelete = () => {
        removeTodo(todo.id);
    };


    return (
        <li className={`${todo.completed ? "item completed" : "item"}`}>
            <div className="item-content">
                <div className="list-nom"><span>{index}</span></div>

                <label className="container">
                    <input type="checkbox"
                           checked={todo.completed}
                           onChange={handleChange}/>
                    <svg viewBox="0 0 64 64">
                        <path className="path"
                              d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"></path>
                    </svg>
                </label>
                <p className={`${todo.completed ? "completed" : "non"}`}>{todo.title}</p>
                <button className="delete-btn" onClick={handleDelete}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="delete">
                        <path
                            d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"/>
                    </svg>
                </button>
            </div>
        </li>
    );
};

export default TodoItem;
